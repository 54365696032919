import React from 'react'
import '../AppStore/AppStore.style.css'
import Select from 'react-select'
import playstore from '../../assets/images/playstore.png';
import appstore from '../../assets/images/appstore.png';
import appgallery from '../../assets/images/appgallery.png';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import useReducerAuthAction from '../../hooks/ReducerHooksAction/useReducerAuthAction';


const AppStore = () => {

  const { t } = useTranslation()
  const language = localStorage.getItem('language')
  const { UPDATE_LANGUAGE } = useReducerAuthAction()

  const languages = [
    {
      label: t('french_france'),
      value: 'fr'
    },
    {
      label: t('english_us'),
      value: 'en'
    },
  ]

  /* CHANGE LANGUAGE */
  const handleChangeLanguage = (value) => {
    i18next.changeLanguage(value)
    UPDATE_LANGUAGE(value)
  }


  return (
    <div className='appstore'>

      <div className='card-content'>

        <img src={require('assets/app/icon_socialhub.png')} className='logo' alt='' />

        <h4 style={{ textAlign: 'justify', fontWeight: '400', fontSize: '14px', color: '#222', marginBottom: '20px' }}>
          {t('with_myopinion_share_and_stay')}
        </h4>

        <a href="https://anader.socialhub.ci/download/anader-social-hub.apk">
          <img className='img-playstore' src={playstore} alt='' />
        </a>

        <a href="https://apps.apple.com/us/app/testflight/id899247664">
          <img className='img-appstore' src={appstore} alt='' />
        </a>

        <a href="https://anader.socialhub.ci/download/anader-social-hub.apk">
          <img className='img-appgallery' src={appgallery} alt='' />
        </a>

        <Select
          placeholder='Pays'
          options={languages}
          isSearchable={false}
          defaultValue={languages.filter(items => items.value === language)}
          onChange={newValue => handleChangeLanguage(newValue.value)}
          className='select-language'
        />

        <div className='terms-conditions'>
          <a style={{ color: 'black', fontSize: '13px', marginRight: '5px' }}
            href={language === 'en' ? 'https://myopinion.ci/legal/en/terms-and-conditions/' : 'https://myopinion.ci/legal/fr/conditions-generales/'}>
            {t('terms_and_conditions')}
          </a>

          <a style={{ color: 'black', fontSize: '13px', marginLeft: '5px' }}
            href={language === 'en' ? 'https://myopinion.ci/legal/en/privacy-policy/' : 'https://myopinion.ci/legal/fr/politique-de-confidentialite/'}>
            {t('privacy_policy')}
          </a>
        </div>

        <p className='copyrigth'>
          {t('app_name')} © {new Date().getFullYear()}
        </p>

        <img src={require('assets/app/icon.png')} alt='' style={{ width: 80, marginLeft: 'auto', marginRight: 'auto', marginBottom: 30 }} />

      </div>

    </div>
  )
}

export default AppStore